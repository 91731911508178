<template>
    <div class="">
        <b-modal content-class="modal-fullscreen" id="modal-message"
                 centered
                 scrollable
                 no-close-on-backdrop
                 hide-footer
                 hide-header>
            <div class="">
                <div class="card bg-transparent text-center">
                    <div class="card-body">
                        <img class="icon mb-3" src="../assets/images/fail.png">

                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-auto message-body">
                                <div v-html="data.title"></div>
                                <div v-html="data.message"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn btn-primary w-100 no-radius btn-lg" @click="close">
                    OK
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
  export default {
    name: 'MessageDialog',
    props: {
      data: Object,
    },
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {
      async close() {
        this.$bvModal.hide('modal-message');
      }
    }
  }
</script>

<style lang="scss">
    @import "../assets/css/colors";

    .icon {
        max-height: 100px;
    }

    #modal-message .modal-body {
        background-color: $black !important;
        padding: 0;
    }

    .modal-backdrop {
        background-color: rgba(0, 0, 0, 0.7) !important;
    }

    #modal-message .modal-content {
        background: transparent;
    }

    #modal-message  .message-body{
        max-width: 400px;
    }

</style>
